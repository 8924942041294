import React from "react"
import {
  Link,
  graphql
} from "gatsby"
import {
  MDXRenderer
} from "gatsby-plugin-mdx"

// import Bio from "../components/bio"
// import Layout from "../components/layout"
// import SEO from "../components/seo"
// import {
//   rhythm,
//   scale
// } from "../utils/typography"


export const BlogPostQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(fields: {slug: {eq: $slug}}) {
      id
      body
      frontmatter {
        title,
        date,
        description
      }
    }
  }
`
interface BlogPostProps {
  data: {
    site: {
      siteMetadata: {
        name: string;
        tagline: string;
        title: string;
        author: string;
      },
    },
    mdx: {
      id: string,
      excerpt: string,
      body: string,
      frontmatter: {
        title: string,
        date: string,
        description: string
      }
    }
  };

}
export default class BlogPostTemplate extends React.Component<BlogPostProps, {}> {
  render() {
    const post = this.props.data.mdx;
    const siteTitle = this.props.data.site;
    console.log(post);
    console.log(siteTitle);
    return (<div>
      <MDXRenderer>{post.body}</MDXRenderer>
    </div>)
  }
}
